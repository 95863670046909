import { Environment, type MediaConfig } from '@foundation-player/loader';
import type { PlayerLoaderConfig, UIConfig } from '@foundation-player/ui';
import { getSeoTitle } from '../SchemaObjects/helper/utils';
import { getVideoDuration, getVideoEncoding } from '../VideoPlayer/helper/utils';
import type { VideoProps, VideoTags } from '../VideoPlayer/VideoPlayerDefault';

type CreateMediaConfigProps = {
  video: VideoProps;
  ivwCode: string;
  ads?: boolean;
  postRoll?: boolean;
  poster?: string;
};

export type SlotType = 'pre' | 'mid' | 'post';

const createAdCall = (id: string, conlen: string, slot: SlotType): string => {
  const params = {
    type: 'vast4', // Definition der AdserverResponse
    fwd_adb: '[BLOCKED]', // placeholder
    s: 'rtl_rtl/news', //???
    sz: slot, // für Preroll. anpassen, wenn es weitere Slots gibt
    tagver: '{TAGVER}', // placeholder, Wert kommt aus dem Player
    fwd_comp: '0', // sind Companion Ads erlaubt? (1 ja, 0 nein?)
    ang_cntp: 'none', // Name des contentpartner
    ang_streamid: crypto.getRandomValues(new Uint32Array(1)).toString(), // random UUID
    ang_conlen: conlen, // ??? Content length, Länge des Content Videos in Sekunden
    fwd_conid: id, // Video ID
    rnd: crypto.getRandomValues(new Uint32Array(1)).toString(), // positive Zufallszahl, mindestens 8 Zeichen(i64)
    ang_plrw: '{PLAYER_WIDTH}', // Player Breite placeholder
    ang_plrh: '{PLAYER_HEIGHT}', // Player Hoehe placeholder
    consent: '{CONSENT}', // placeholder
  };

  return 'https://ad-ipd.sxp.smartclip.net/select' + '?' + new URLSearchParams(params).toString();
};

export const createUiConfig = (poster?: string): UIConfig => ({
  poster: poster,
  allowNativeVideoFullscreen: true,
});

export const createPlayerLoaderConfig = (consentString: string, ads: boolean = true): PlayerLoaderConfig => ({
  client: 'rtldigitalnews',
  scope: 'rtl',
  environment: (process.env.NEXT_PUBLIC_DEPLOYMENT_STAGE as Environment) ?? 'dev',
  consentString: consentString,
  pluginConfigs: {
    'foundation.plugin.advertising': {
      enabled: ads,
      smartclipConfig: {
        adReinsertion: {
          homad: {
            enabled: true,
            setup: {
              clientConfig: {
                // alias: 'dentvadipdsxpsmartclipnet',
                // config: 'https://hgc-cf-cache-1.svonm.com/www.n-tv.de/config.json',
                // enabled: true,
                // server: [
                //   'https://ssl.1.damoh.n-tv.de/[hash]/',
                //   'https://ssl.2.damoh.n-tv.de/[hash]/',
                //   'https://ssl.3.damoh.n-tv.de/[hash]/',
                // ],
              },
            },
          },
        },
      },
    },
    'foundation.plugin.googleAnalytics4': {
      enabled: true,
    },
    // 'foundation.plugin.heartbeat': {
    //   enabled: true,
    //   endpoint: {
    //     vod: 'https://px1.vtrtl.de/vt/hb.do',
    //     live: 'https://px1.vtrtl.de/vtl/hb.do',
    //   },
    //   isKidsProfile: false,
    //   offer: getBreakpoint() === 'xs' ? 'mobntv' : 'ntv',
    //   payStatus: 0,
    //   userStatus: 0,
    //   videoService: videoModeMap.videoService[videoMode],
    // },
    'foundation.plugin.nielsen': {
      enabled: true,
    },
  },
});

export const createMediaConfig = ({
  video,
  ivwCode,
  ads = true,
  postRoll = true,
  poster = 'https://www.rtl.de/fallbackImage.jpg',
}: CreateMediaConfigProps): MediaConfig => {
  const { fields, sourceId } = video;
  const videoDuration = getVideoDuration(fields.encoding);

  return {
    advertising: {
      slots: [
        {
          adCall: ads ? createAdCall(sourceId, videoDuration.toString(), 'pre') : undefined,
          offset: 0,
          type: 'LINEAR',
        },
        {
          adCall: ads && videoDuration > 480 ? createAdCall(sourceId, videoDuration.toString(), 'mid') : undefined,
          offset: 180,
          type: 'LINEAR',
        },
        {
          adCall:
            ads && postRoll && videoDuration > 30
              ? createAdCall(sourceId, videoDuration.toString(), 'post')
              : undefined,
          offset: -1,
          type: 'LINEAR',
        },
      ],
    },
    autoplay: false,
    id: sourceId,
    manifests: [
      {
        type: 'progressive',
        sources: [
          {
            url: getVideoEncoding(fields.encoding)?.value['content-url'].value ?? '',
            priority: 'main',
          },
        ],
      },
      {
        type: 'dashhd',
        sources: [
          {
            url: getVideoEncoding(fields.encoding, 'dash')?.value['content-url'].value ?? '',
            priority: 'fallback',
          },
        ],
      },
      {
        type: 'hlsfairplayhd',
        sources: [
          {
            url: getVideoEncoding(fields.encoding, 'hls')?.value['content-url'].value ?? '',
            priority: 'fallback',
          },
        ],
      },
    ],
    metadata: {
      title: decodeURIComponent(fields.title),
      poster: poster,
      headline: decodeURIComponent(fields.title),
      secondaryHeadline: decodeURIComponent(fields?.subtitle ?? ''),
      firstPublicationDate: video.createdAt ?? '',
      lastPublicationDate: video.lastmodifiedAt ?? '',
      userStarted: true,
    },
    plugins: {
      'foundation.plugin.heartbeat': {
        id: sourceId,
        ivw: ivwCode,
        startType: 0,
      },
      'foundation.plugin.nielsen': {
        commentPath: ivwCode,
        id: sourceId,
      },
      'foundation.plugin.googleAnalytics4': {
        firstHeadline: getSeoTitle(fields['first-title'], fields['first-subtitle']),
        headline: getSeoTitle(fields.title, fields.subtitle),
        tags: video.tags?.map((item: VideoTags) => item.name),
        firstPublicationDate: video.publishedAt ?? undefined,
        lastPublicationDate: video.lastmodifiedAt ?? undefined,
        contentType: 'video',
      },
    },
    type: 'vod',
  };
};
