'use client';
import { useContext, useEffect, useRef, useState } from 'react';
import { type FoundationError } from '@foundation-player/loader';
import { createPlayerUI } from '@foundation-player/ui';
import { AppContext } from 'context/AppContext';
import { useConsent } from 'customHooks/useConsent';
import { useQueryExist } from 'customHooks/useQueryExist';
import { getImageLoader } from 'utils/imageLoader';
import type { VideoProps } from '../VideoPlayer/VideoPlayerDefault';
import { createPlayerLoaderConfig, createMediaConfig, createUiConfig } from './config';
import styles from './FoundationPlayer.module.scss';

const FoundationPlayer = ({ video, postRollDisabled }: { video: VideoProps; postRollDisabled?: boolean }) => {
  const {
    analyticsData: { ivwCode },
    pageMetaData: { noVideoAds },
  } = useContext(AppContext);
  const {
    consent: { tcString },
    isConsentReady,
  } = useConsent();
  const adsDisabled = useQueryExist('video.adsOff') || noVideoAds || video.fields['ads-free'] === 'true';
  const playerElementRef = useRef<HTMLDivElement>(null);
  const posterUrl = getImageLoader(video.image.baseUrl, video.image.filename, 'c16_9').loader({
    src: video.image.baseUrl,
    width: 1024,
  });

  const [playerCreated, setPlayerCreated] = useState<boolean>(false);

  async function createPlayerUIAndLoadMedia() {
    try {
      if (playerElementRef.current && !playerCreated) {
        setPlayerCreated(true);
        const uiConfig = createUiConfig(posterUrl);
        const playerLoaderConfig = createPlayerLoaderConfig(tcString, !adsDisabled);
        const mediaConfig = createMediaConfig({
          video,
          ivwCode,
          ads: !adsDisabled,
          postRoll: !postRollDisabled,
          poster: posterUrl,
        });
        const player = await createPlayerUI(playerElementRef.current, playerLoaderConfig, uiConfig);
        player.loadMedia(mediaConfig).catch((error: FoundationError) => {
          player.destroy(error);
        });
      }
    } catch (e) {
      console.log('Error:', e);
    }
  }

  useEffect(() => {
    if (!playerCreated && isConsentReady) {
      createPlayerUIAndLoadMedia();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isConsentReady]);

  return (
    <div ref={playerElementRef}>
      {!playerCreated && (
        <video poster={posterUrl} data-testid={video.id} className={styles.video}>
          <track kind="captions" />
        </video>
      )}
    </div>
  );
};

export default FoundationPlayer;
